import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Layout from '../components/layout'
import Section from '../components/section'
import SEO from '../components/seo'
import { options } from '../transform/contentful-rich-text-options'
import { rhythm } from '../utils/typography'

const ContentfulPostText = styled.div`
  .contentful-image-container {
    display: flex;
    justify-content: center;
  }

  .contentful-post-link-container {
    max-width: 90%;
    margin: 1rem auto 2rem;
    background: #fff;
    border-radius: 0.5rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    box-shadow: 3px 3px 10px 2px #888;
    position: relative;

    &::after {
      content: 'PRZECZYTAJ';
      position: absolute;
      font-family: sans-serif;
      font-weight: bold;
      left: 1rem;
      top: 1rem;
      padding: 0.3rem;
      font-size: 0.7rem;
      text-align: center;
      border-radius: 0.3rem;
      background: #ffffffdd;
    }

    &:hover {
      box-shadow: 3px 3px 10px 0px #888;
    }

    .text-container {
      text-align: center;
      font-size: 90%;
      padding: 0.5rem 1rem;
    }

    .image-container {
      align-self: stretch;
      flex: 200px 1 0;
    }

    img {
      max-width: 200px;
      margin-bottom: 0;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    h4 {
      letter-spacing: 0px;
      margin-top: 0;
      margin-bottom: 0.5rem;
    }

    p {
      margin: 0;
      max-height: 60px;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    @media (max-width: 650px) {
      flex-direction: column;
      max-width: 400px;

      .image-container {
        max-height: 200px;
        margin-bottom: 0.5rem;
        border-bottom: 1px solid #eee;
        flex-basis: 200px;
        overflow: hidden;
      }
      img {
        max-width: none;
        height: 200px;
      }
    }
  }
`

const PageContentfulTemplate = ({ data, location }) => {
  const post = data.contentfulPage
  const siteTitle = data.site.siteMetadata.title

  // simulate media-queries for 960px breakpoint
  const [maxWidth, setMaxWidth] = useState(
    typeof window !== 'undefined' &&
      window.innerWidth &&
      window.innerWidth > 960
      ? '720px'
      : '70%'
  )

  const handleResize = () => {
    setMaxWidth(
      typeof window !== 'undefined' &&
        window.innerWidth &&
        window.innerWidth > 960
        ? '720px'
        : '70%'
    )
  }

  if (typeof window !== 'undefined') window.onresize = handleResize

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        location={location}
        title={post.title}
        description={post.lead.lead}
      />
      {/* Main image */}
      <Section maxWidth="100%" maxHeight="400px" padding="0">
        <Img
          fluid={post.mainImage.fluid}
          alt={post.mainImage.description || ''}
        />
      </Section>
      <Section maxWidth={maxWidth}>
        {/* Title */}
        <h1
          style={{
            marginTop: rhythm(0.5),
          }}
        >
          {post.title}
        </h1>

        {/* Lead */}
        <p
          style={{
            fontWeight: 'bold',
          }}
        >
          {post.lead.lead}
        </p>
        {/* Content */}
        <ContentfulPostText>
          {documentToReactComponents(post.content.json, options)}
        </ContentfulPostText>
      </Section>
    </Layout>
  )
}

export default PageContentfulTemplate

export const pageQuery = graphql`
  query ContentfulPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPage(slug: { eq: $slug }) {
      title
      lead {
        lead
      }
      mainImage {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      content {
        json
      }
    }
  }
`
